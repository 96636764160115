import React from "react";
import { graphql } from "gatsby";
import {Helmet} from "react-helmet";

import DefaultLayout from "../components/layouts/default";
import FranchiseIcon from "../components/franchiseicon/FranchiseIcon";
import Header from "../components/layouts/index/Header";
import TaglineList from '../components/taglinelist/TaglineList';

import transformTaglines from "../utils/transform-tagline";

export default ({ data, pageContext }) => {
    let taglines = transformTaglines(data.realTaglines.taglines).slice().reverse();

    let season = taglines[0].node.seasonList.edges[0].node;
    let franchise = season.franchise;

    let experiences = {};
    pageContext.seasonSet.edges.forEach(
        ({ node }) => { 
            experiences["season_" + node.number] = { dest: '/' + ['season', franchise.slug, node.number].join('/').toLowerCase(), text: node.number }; 
        }
    );

    let blurb = `Every tagline from the <strong>${franchise.city}</strong> franchise of the Real Housewives - ranked!`;

    return (<DefaultLayout>
        <Helmet>
            <title>{ `Real Housewives of ${franchise.city} ${season.number} Complete Tagline Ranking!` }</title>
        </Helmet>
        <Header 
            blurb={ blurb } 
            experiences={ experiences } 
            experienceLabel="Seasons:" 
            headerIcon={ <FranchiseIcon franchise={ franchise } /> } />
        <TaglineList taglines={ taglines } showFilterMenu={ true } />
    </DefaultLayout>);
}

export const query = graphql`
    query($franchise: String) {
        realTaglines {
            taglines(season_Franchise_Slug: $franchise) {
                edges {
                    node {
                        id
                        tagline
                        rank
                        slug
                        housewife {
                            fullName
                            slug
                            housewifeimageSet {
                                crops {
                                    height
                                    name
                                    url
                                    width
                                }
                            }
                        }
                        seasonList:season {
                            edges {
                                node {
                                    number
                                    franchise {
                                        city
                                        slug
                                    }
                                }
                            }
                        }
                        taglinescore {
                            total
                        }
                    }
                }
            }
        }
    }
`